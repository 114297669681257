import React, { useEffect, useState } from 'react';

import { NewTag } from '../../atoms/NewTag/NewTag';
import Icon from '../../atoms/Icon/Icon';
import { getUser } from '../../../api/userAPI';
import './TalentClientCard.scss';
import {
  getConsultantsByTalents,
  // getBeneficiary
} from '../../../api/ConsultantApi';
import { getUserProfile } from '../../../api/ProfileApi';
import { getBeneficiary } from '../../../api/BeneficiaryAPI';

export const TalentClientCard = ({ talent, mission, onClick, user = null, subRole = "", deleteTalent = null, isTalentView = true }) => {
  const renderAvatar = (image) => {
    if (image) return <img src="" alt="avatar-member" />;
    return (
      <div className="avatar">
        <Icon icon="person" color={'accent'} />
      </div>
    );
  };

  const [consultantOfT, setconsultantOfT] = useState();
  useEffect(() => {
    handleConsultantOfTalent();
  }, []);

  const handleConsultantOfTalent = async () => {
    try {
      const consultants = await getConsultantsByTalents(talent.id);
      const consultant_primary = consultants.consultants.filter(consultant => consultant.is_primary === 1);
      setconsultantOfT(consultant_primary[0]);
    } catch (error) {
    }
  };
  // if (!talent) return null;
  const { first_name, last_name, email, phone, image } = talent;
  const hoverH1 = (hover) => {
    if (hover) {
        document.getElementById(`consultant-mission-card-${talent.id}`).style.background = "#efeffe"
    } else {
        document.getElementById(`consultant-mission-card-${talent.id}`).style.background = "white"
    }
}
  return (
    <div
      id={`consultant-mission-card-${talent.id}`}

      className="talent-client-card"
      // onClick={onClick ? onClick : undefined}
      // style={onClick && { cursor: 'pointer' }}
    >
      <div>
        <div className="talent-client-card-header">
          {
            <NewTag
              key={talent.id}
              title={
                consultantOfT
                  ? `${consultantOfT.user.last_name} ${consultantOfT.user.first_name}`
                  : ''
              }
            />
          }
        </div>
        <div className="talent-client-card-content">
          <div className="talent-informations">
            {isTalentView && renderAvatar(image)}
            <h1 onMouseEnter={() => hoverH1(true)} onMouseLeave={() => hoverH1(false)} onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default', paddingLeft: '10px' }} >
              {first_name} {last_name}
            </h1>
          </div>
          {isTalentView && <div><p>{email}</p>
          <p>{phone}</p></div>}
          
        </div>
      </div>
      <div>
      {((subRole === "consultant_admin" || (user && mission.responsable[0].id === user.id)) && deleteTalent) && <div style={deleteTalent && { cursor: 'pointer' }} onClick={() => deleteTalent(talent.id)}>
                    <Icon  icon={"trash2"}/>
                </div>}
                </div>
    </div>
  );
};
