import React, { useEffect, useState } from 'react';
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle';
import Icon from '../../atoms/Icon/Icon';
import InputField from '../../molecules/InputField/InputField';
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/CentralTestRedux';
import './CentralTestOfficeManagerCard.scss';
import {
  createCompagny,
  assignCompagny,
  putTestNumberUpdate,
  getOfficeInfos,
  getCompagnyTests,
} from '../../../api/CentralTestApi';
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner';
const CentralTestOfficeManagerCard = ({ centraltestId = null, officeId = null }) => {
  const [id, setId] = useState(centraltestId);
  const [credits, setCredits] = useState(0);
  const [nbrCredits, setNbrCredits] = useState(0);

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [officeInfo, setOfficeInfo] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetOfficeInfoCentralTest();
  }, [officeId]);

  const handleChange = ({ _name, value }) => {
    setId(value);
  };

  const handleChangeCredits = ({ _name, value }) => {
    setCredits(value);
  };

  const HandleCreateCompagny = async (type) => {
    if (officeId) {
      setLoading(true);
      const rep = await createCompagny({ office_id: officeId, centraltest_connexion_type: type });
      setLoading(false);
    }
  };

  const HandleAssignCompagny = async (type) => {
    if (officeId) {
      if (id) {
        setLoading(true);
        console.log({
          office_id: officeId,
          centraltest_connexion_type: type,
          centraltest_company_id: id,
        });
        const rep = await assignCompagny({
          office_id: officeId,
          centraltest_connexion_type: type,
          centraltest_company_id: id,
        });

        setLoading(false);
        handleGetOfficeInfoCentralTest();
      } else {
        setErrorCode('Veuillez renseigner un identifiant');
      }
    }
  };

  ///TMP
  const handleGetAllTests = async () => {
    if (officeId) {
      setLoading(true);
      const rep = await getCompagnyTests(officeId);
      console.log(rep);
      setLoading(false);
    }
  };
  ///TMP

  const handleGetOfficeInfoCentralTest = async () => {
    if (officeId) {
      setLoading(true);
      try {
        const rep = await getOfficeInfos(officeId);
        console.log(rep);
        setOfficeInfo(rep);
        setNbrCredits(rep.nb_credits);
        setId(rep.id);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAddCredits = async () => {
    if (credits) {
      setLoading(true);
      const rep = await putTestNumberUpdate({ office_id: officeId, tests_number: credits });
      setLoading(false);
      handleGetOfficeInfoCentralTest();
    }
  };

  return (
    <div className="centraltest-office-manager-card">
      {loading && <LoadingSpinner />}
      <IconCardTitle label="Central Test" icon={Icon.icon.CentralTest} />

      {/* {!officeInfo && (
        <div className="card">
          <div className="card-title">Création de compagnie CentralTest</div>
          <div className="card-content-flex">
            <PrimaryButton
              id="office_centraltest_createCompagny_"
              label="Créer un compte avec MyTalents"
              onClick={() => HandleCreateCompagny('mytalents')}
            />
            <PrimaryButton
              id="office_centraltest_id"
              label="Créer un compte Externe"
              onClick={() => HandleCreateCompagny('external')}
            />
          </div>
        </div>
      )} */}

      <div className="card">
        <div className="card-title">Association compte</div>
        {officeInfo && <p>Le compte est déjà lié</p>}
        <div className="card-content">
          <InputField
            name="name"
            placeholder="Identifiant Central Test"
            onChange={handleChange}
            value={id}
            error={errorCode ? { message: errorCode } : null}
          />
          <div className="card-content-flex">
            <PrimaryButton
              id="office_centraltest_id"
              label="Lier le compte avec MyTalents"
              onClick={() => HandleAssignCompagny('mytalents')}
            />
            <PrimaryButton
              id="office_centraltest_id"
              label="Lier le compte Externe"
              onClick={() => HandleAssignCompagny('external')}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-title">Gestion des crédits CentralTest</div>

        <div className="card-content">
          <p style={{ fontSize: '16px', width: '100%', textAlign: 'center' }}>
            Nombre de crédits : <b style={{ fontSize: '20px' }}>{nbrCredits ?? 0}</b>
          </p>
          <div className="card-content-flex">
            <InputField
              name="name"
              type={'number'}
              placeholder="Nombre de Crédits"
              onChange={handleChangeCredits}
              value={credits}
            />
            <div className="card-content-flex">
              <PrimaryButton
                id="office_centraltest_id"
                label="Définir un nombre de crédits"
                onClick={() => handleAddCredits()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CentralTestOfficeManagerCard;
