import 'moment/locale/fr';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isValid, reduxForm } from 'redux-form';
import {
  getValidationRules,
  profileFieldsForConsultant,
} from '../../../helpers/beneficiaryProfileForm';
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator';
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux';
import { selectors as OfficeSelectors } from '../../../redux/OfficeRedux';
import Page from '../../global/Page/Page';

import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary';
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside';

import './ConsultantBeneficiaryExplorerContainer.scss';
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton';

import IllustrationJobs from '../../../static/illustrations/illustration_jobs.svg';
import IllustrationApplication from '../../../static/illustrations/illustration_jobs_application.svg';
import CircleButton from '../../../components/atoms/CircleButton/CircleButton';
import DataTable from '../../../components/molecules/DataTable/DataTable';
import { useSelector } from 'react-redux';
import {
  getCompagnyTests,
  putAssignTest,
  getTalentsTests,
  getTalentMap,
  postResendTest,
} from '../../../api/CentralTestApi';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import { Popin } from '../../../containers/global/Popin/Popin';
import AssignTestModal from './AssignTestModal/AssignTestModal';
import { set } from 'lodash';

const mapStateToProps = (state, props) => ({
  valid: isValid('beneficiary-profile')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
});

const columns = [
  'Nom du test',
  'Statut du test',
  'Débuté le',
  'Terminé le',
  'Rapport consultant',
  'Actions',
];

const modalStyles = {
  // minWidth: "670px",
};

const mapDispatchToProps = (dispatch) => ({});

const ConsultantBeneficiaryExplorerContainer = ({ beneficiary }) => {
  const [office, setOffice] = useState(null);
  const u = useSelector(UserSelectors.user);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [tests, setTests] = useState([]);
  const [individualTests, setIndividualTests] = useState([]);

  const renderAside = () => {
    return <ConsultantBeneficiaryAside />;
  };

  const formatData = (data) => {};
  const getData = async () => {
    if (!office && tests.length > 0) return;
    if (office?.id) {
      setLoading(true);
      await getCompagnyTests(office.id).then((data) => {
        console.log(data);
        setTests(data);
      });
      await getTalentsTests(beneficiary.id).then((data) => {
        setIndividualTests(data);
        console.log(data);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [office]);

  useEffect(() => {
    if (u && u.office) {
      setOffice(u.office);
    }
  }, [u]);

  const handleAssign = async (res) => {
    setLoading(true);
    const data = {
      user_id: beneficiary.id,
      consultant_id: u.profile_id,
      test_id: res.test.id,
      test_title: res.test.label,
      detail_id: res.detail?.id ?? null, //optional only IT test
      detail_level_id: res.level?.value ?? null, //optional only IT test
      test_language_id: res.lang?.id ?? null, //optional only IT test
      office_id: office.id,
    };
    console.log(data);
    const response = await putAssignTest(data);
    console.log(response);
    await getData();
    setLoading(false);

    setShowAddModal(false);
  };

  const handleGetTalentMap = async () => {
    setLoading(true);
    const response = await getTalentMap(beneficiary.id);
    console.log(response);
    setLoading(false);
  };

  const handlePostResendTest = async (testId) => {
    setLoading(true);
    const data = {
      user_id: beneficiary.id,
      test_id: testId,
    };
    const response = await postResendTest(data);
    console.log(response);
    setLoading(false);
  };

  const renderContent = () => {
    return (
      <div className="beneficiary-explorer-container">
        {loading && <LoadingSpinner />}
        <div className="consultant-beneficiary-explorer-table">
          <h2>Central Test</h2>
          {u.role === 'consultant' && (<div
            className="invite-test-wrapper"
            style={{ display: 'flex', gap: '10px', paddingBottom: '25px', paddingTop: '10px' }}
          >
            <CircleButton icon={'plus'} onClick={() => setShowAddModal(true)} />
            Inviter à passer le test
          </div>)}
          

          <div className="tests-list-wrapper">
            <DataTable columns={columns} data={individualTests} postResendTest={handlePostResendTest} />
          </div>
          {individualTests.length >= 2 && (
            <div style={{ paddingTop: '25px' }}>
              <PrimaryButton label={'Talent map'} onClick={() => handleGetTalentMap()} />
            </div>
          )}
        </div>
        <AssignTestModal
          isOpen={showAddModal}
          onClose={() => setShowAddModal(false)}
          onAssign={handleAssign}
          beneficiary={beneficiary}
          tests={tests}
        />
      </div>
    );
  };

  return (
    <ConsultantBeneficiary renderAside={renderAside} disableSave>
      <div className="consultant-beneficiary-explorer-page">{renderContent()}</div>
    </ConsultantBeneficiary>
  );
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Page(
    reduxForm({
      form: 'beneficiary-profile',
      asyncBlurFields: profileFieldsForConsultant,
      asyncChangeFields: profileFieldsForConsultant,
      asyncValidate: (values, dispatch, props) => {
        const validationRules = getValidationRules(props.beneficiary, values, true);
        return asyncValidate(values, validationRules);
      },
    })(ConsultantBeneficiaryExplorerContainer),
  ),
);

export default ConnectedComponent;
